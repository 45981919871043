.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  background-color: #282c34;
}

h1, h2, h3 {
  margin: 0.5em 0;
}

p {
  margin: 0.5em 0;
}

header {
  position: relative;
  background: url('/src/assets/images/header-background.jpg') center/cover no-repeat;
  color: white;
  height: 100vh;
  display: flex;
  align-items: start;
  justify-content: center;
  text-align: center;
}

.header-content {
  width: 100%;
  height: 40%;
  padding: 30px 60px 30px 30px;
  display: flex;
  justify-content: right;
  align-items: start;
  gap: 50px;
}
.header-info{
  display: flex;
  justify-content: start;
  align-items: end;
  flex-direction: column;;
}

.header-title1{
  font-size: 42px;
  margin: 0
}

.header-title2{
  font-size: 32px;
}

.logo {
  width: 15%;
  height: auto;
  /*position: absolute;*/
  /*top: 10%;*/
  /*right: 10%*/
  max-width: 300px;
}

.gallery {
  padding: 20px;
  background: #f9f9f9;
  text-align: center;
}

.gallery-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.gallery-item {
  width: 30%;
  overflow: hidden;
  background-color: rgb(52,52,52);
  border-radius: 5px;
  aspect-ratio: 0.9;
  position: relative;
  cursor: pointer;
}
.gallery-item:hover .gallery-overlay {
  height: 100%;
  transition: .5s ease;
  transform: translateY(0%);
}


.gallery-item:hover .gallery-text  {
  height: 100%;
  transition: .5s ease;
  transform: translateY(0%);
}

.gallery-item:hover .additional-gallery-text {
  opacity: 1;
  transform: translateY(0%);
  transition: 0.5s ease;
}

.gallery-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.68);
  width: 100%;
  height: 100%;
  min-height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: .5s ease;
  transform: translateY(80%);
}



.gallery-text {
  position: absolute;
  bottom: 0;
  left: 2%;
  right: 2%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: center;
  font-weight: bold;
  width: 96%;
  font-size: 24px;
  color: white;
  height: 20%;
  transition: .5s ease;
}

.additional-gallery-text {
  position: absolute;
  bottom: 0;
  left: 2%;
  right: 2%;
  width: 96%;
  font-size: 20px;
  line-height: 1.2;
  color: #ddd;
  text-align: center;
  padding-bottom: 10%;
  transform: translateY(20%);
  opacity: 0;
  transition: 0.5s ease;
}



.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}


.gallery-item div {
  color: white;
  justify-content: center;
  align-items: center;
}




.footer {
  background-color: #282c34;
  color: #fff;
  padding: 2rem 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-between;
}

.footer-about {
  flex: 1;
  min-width: 100px;
}
.footer-contact {
  flex: 0.8;
  min-width: 100px;
  /*max-width: 300px;*/
}

.contact-form{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.footer-about h2,
.footer-contact h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.footer-socials {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.footer .phone-number {
  font-size: 14px;
}


.social-icon {
  font-size: 1.5rem;
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #4caf50;
}

.contact-form input,
.contact-form textarea {
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 4px;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: 2px solid #ffffff;
}

.btn-send {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  background-color: #ffffff;
  color: #000000;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.btn-send:hover {
  background-color: rgba(255, 255, 255, 0.74);
}

.status-message {
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #4caf50;
}


.main-section {
  position: relative;
  background-color: #282c34;
  color: white;
  min-height: 40vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
}

.main-content {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 60%;
  font-size: 42px;
  font-weight: bold;
  padding: 100px 50px 100px 50px
}


.language-selector {
  position: relative;
  display: inline-block;
}

.dropdown {
  position: relative;
}

.dropdown-btn {
  background-color: rgba(255, 255, 255, 0.5);
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  vertical-align: center;
}

.dropdown-btn:hover {
  background-color: #0056b3;
}

.dropdown-content {
  display: block;
  position: absolute;
  right: 0;
  background-color: rgba(255, 255, 255, 0.77);
  min-width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 1000;
  margin-top: 5px;
  overflow: hidden;
}


.dropdown-item {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.flag {
  margin-right: 10px;
  font-size: 18px;
}

.language-name {
  font-size: 16px;
  color: #333;
}

input,
textarea {
  font-family: inherit;
  font-size: inherit;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

textarea {
  resize: none; /* Prevent resizing for consistency */
  height: auto; /* Make it behave more like an input */
}

::placeholder {
  color: #aaa;
  font-style: italic; /* Optional */
}

@media (max-width: 1200px) and (min-width: 768px) {
  .footer-container {
    justify-content: center;
    align-items: center;
    padding: 30px;
  }

  .dropdown-btn {
    font-size: 14px;
  }

  .dropdown-item {
    font-size: 14px;
  }

  .flag {
    font-size: 16px;
  }

  .gallery-item {
    width: 45%; /* Adjusted width for medium screens */
  }

  .additional-gallery-text {
    font-size: 16px;
  }

  .header-title1 {
    font-size: 36px; /* Slightly smaller for medium screens */
  }

  .header-title2 {
    font-size: 28px;
  }

  .main-content {
    font-size: 36px; /* Adjust the main content font size */
  }

  .logo {
    width: 20%; /* Adjust logo size for medium screens */
    max-width: 200px;
  }
}

/* Styles for screens smaller than 768px */
@media (max-width: 768px) {

  .gallery-item:hover .gallery-text  {
    transform: translateY(-30%);
  }

  .gallery-text{
    font-size: 22px;
  }

  .additional-gallery-text  {
    font-size: 18px;
  }

  .header-info{
    display: flex;
    justify-content: start;
    align-items: start;
  }
  .dropdown-content {
    left: 0;
  }

  .footer-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .dropdown-btn {
    font-size: 12px;
  }

  .dropdown-item {
    font-size: 12px;
  }

  .flag {
    font-size: 14px;
  }

  .gallery-item {
    width: 100%; /* Single column layout for small screens */
  }

  .header-title1 {
    font-size: 28px;
    text-align: start;
  }

  .header-title2 {
    font-size: 20px;
    text-align: start;
  }

  .main-content {
    font-size: 28px; /* Reduced font size for main content */
    padding: 50px 20px; /* Smaller padding */
  }

  .logo {
    width: 25%; /* Adjust logo size further */
    max-width: 150px;
  }

  .footer {
    flex-direction: column; /* Stack footer sections vertically */
    text-align: center;
  }

  .footer-socials {
    justify-content: center;
  }


}


